import { render, staticRenderFns } from "./DialogImage.vue?vue&type=template&id=1d9c1dbb&scoped=true"
import script from "./DialogImage.vue?vue&type=script&lang=js"
export * from "./DialogImage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../school_end/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d9c1dbb",
  null
  
)

export default component.exports